import img1 from '../../src/images/data/q-1.jpg';
import img2 from '../../src/images/data/q-2.jpg';
import img3 from '../../src/images/data/q-3.jpg';
import img4 from '../../src/images/data/q-4.png';

import logo from '../../src/images/data/q/logo-q.jpg';

const q = {
    data : {
        wp: {
            q: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                    buttons : [
                        {
                            links : '/tv/packages',
                            label : 'Show Packages'
                        }
                    ]
                },
                options : [
                    {
                        id: 'q-apps',
                        text : '<h1>All your TV.<br/>All your apps.</h1><p>See your Sky TV next to your Netflix, YouTube, Prime Video, Spotify and All4. Everything you love, all in one place, easy. </p>',
                        image: {
                            mediaItemUrl:img1
                        },
                    },
                    {
                        id: 'q-ent',
                        text : '<h1>Entertainment <br/>made easy</h1><p>Watch live and on demand. <br/>On your TV, online and on the go.</p>',
                        image: {
                            mediaItemUrl:img2
                        },
                    },
                    {
                        id: 'q-hd',
                        text : '<h1>HD and UHD</h1><p>With HD there are over 40 channels in sharper more vivid detail, allowing you to get closer to the action and deeper into the drama. Get even closer to the TV you love with Ultra HD - now comes with High Dynamic Range, making darks darker and brights brighter. With vibrant and intense colours and more true-to-life picture get ready to watch in the best possible picture quality. </p>',
                        image: {
                            mediaItemUrl:img3
                        },
                    },
                    {
                        id: 'q-multiscreen',
                        text : '<h1>Multiscreen</h1><p>Sky Multiscreen fills your home with entertainment. Watch on more TVs with wireless Sky Q Mini boxes.</p>',
                        image: {
                            mediaItemUrl:img4
                        },
                    },
                ]
            }
        },

    }
}

export default q;